const Warning = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92">
      <g fill="none" fillRule="evenodd">
        <g fill="#E0E0E0" fillRule="nonzero">
          <g>
            <path
              d="M51 11.34c1.52.877 2.783 2.14 3.66 3.66l22.517 39c2.761 4.783 1.123 10.899-3.66 13.66-1.52.878-3.245 1.34-5 1.34H23.483c-5.523 0-10-4.477-10-10 0-1.755.462-3.48 1.34-5L37.34 15C40.1 10.217 46.217 8.578 51 11.34zm-3 5.196c-1.851-1.069-4.202-.49-5.353 1.283l-.111.181-22.517 39c-.35.608-.536 1.298-.536 2 0 2.142 1.684 3.891 3.8 3.995l.2.005h45.034c.702 0 1.392-.185 2-.536 1.851-1.069 2.525-3.394 1.565-5.277L71.981 57 49.464 18c-.351-.608-.856-1.113-1.464-1.464z"
              transform="translate(-772 -279) translate(772 279)"
            />
            <path
              d="M48.66 47l1.08-9v-7.2h-6.48V38l1.08 9h4.32zm-2.16 9.504c1.872 0 3.42-1.548 3.42-3.42 0-1.872-1.548-3.42-3.42-3.42-1.872 0-3.42 1.548-3.42 3.42 0 1.872 1.548 3.42 3.42 3.42z"
              transform="translate(-772 -279) translate(772 279)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Warning;
