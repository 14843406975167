/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Warning from '../public/icon/Warning';
import Link from 'next/link';
import media from '@shared/mediaQuery';

export default function Custom404() {
  return (
    <div css={[style]} className="page-layout">
      <div className="notfoundpage--wrap">
        <Warning />
        <h1 className="notfoundpage--title">페이지를 찾을 수 없습니다</h1>
        <p className="notfoundpage--description">
          요청하신 페이지가 사라졌거나,
          <br className="mobile" /> 잘못된 경로를 입력하셨습니다.
        </p>
        <Link href="/">
          <a className="notfoundpage--link">변호사스퀘어 홈으로 돌아가기</a>
        </Link>
      </div>
    </div>
  );
}

const style = css`
  .notfoundpage {
    &--wrap {
      margin: 196px auto 270px;
      width: 420px;
      text-align: center;
      ${media.mobile} {
        width: 270px;
        margin: 76px auto;
      }
    }
    &--title {
      font-size: 28px;
      font-weight: normal;
      color: #333333;
      margin-top: -5px;
      ${media.mobile} {
        font-size: 24px;
      }
      &:after {
        content: '.';
        font-family: 'Pretendard-Bold';
        color: #ff5500;
        font-size: 28px;
      }
    }
    &--description {
      font-size: 16px;
      color: #555555;
      margin-top: 8px;
      ${media.mobile} {
        font-size: 14px;
      }
    }
    &--link {
      font-size: 15px;
      color: #757575;
      font-family: 'Pretendard-Bold';
      width: 240px;
      height: 46px;
      border: 1px solid #e0e0e0;
      display: block;
      line-height: 47px;
      margin: 40px auto 0;
    }
  }
`;
